import WelcomePage from "../components/Homepage/WelcomePage";
// import HomeNavbar from "../components/Navbar";
// import ValuesComponent from "../components/Homepage/Values";
// import ContactComponent from "../components/Homepage/Contact";
// import BrandsComponent from "../components/Homepage/Brands";
// import Footer from "../components/Footer";

const HomePage = () => {

    return (
		<div id="home" className="main-container">
			{/* <HomeNavbar theme="light" /> */}
			<WelcomePage />
			{/* <ValuesComponent />
			<BrandsComponent />
			<ContactComponent />
			<Footer theme="dark" /> */}
		</div>
	);
}

export default HomePage;