import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePage from './views/HomePage';
// import Discover from './views/Discover';
// import Cursor from './components/Cursor';
// import Tests from './views/Tests';

import "bootstrap/dist/css/bootstrap.min.css";
import "./sass/base.css";
import "./sass/theme.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  	<React.StrictMode>
		{/* <Cursor /> */}
		<Router>
			<Routes>
				<Route path="/home" element={<HomePage />}/>
				{/* <Route path="/discover/:category" element={<Discover />}/> */}
				{/* <Route path="/test" element={<Tests/>}/> */}
				<Route path="*" element={<Navigate to={'/home'} replace />}/>
			</Routes>
		</Router>
	</React.StrictMode>
);