import { useNavigate } from "react-router-dom";
import LogoLight from '../../assets/logo-light/logo-light.svg'

const WelcomePage = () => {

	const navigate = useNavigate();

    return (
        
        <div className="welcome-container">
            {/* <div className="home-element">
                <span></span>
                <span></span>
                <span></span>
            </div> */}
            <img alt="Wilem logo" onClick={ () => navigate('./home') } className="home-logo-middle" src={ LogoLight } />
            <p id="construction-tmp">UNDER CONSTRUCTION...</p>
        </div>
	);
}

export default WelcomePage;